import gsap from "gsap"
import { ReactComponent as ArrowsSVG } from "images/global/LaunchArrows.svg"
import { loader } from "library/Loader"
import { usePreloader } from "library/Loader/PreloaderUtils"
import { fresponsive } from "library/fullyResponsive"
import { useRef, useState } from "react"
import Compass from "sections/home/01-Hero/Compass"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"

const duration = 1
const delay = 1

export const Preloader = () => {
	const wrapper = useRef<HTMLDivElement>(null)
	// sync heading between two compasses
	const [heading, setHeading] = useState(0)

	const fadeOut = () => {
		const tl = gsap.timeline({})
		tl.to(wrapper.current, {
			duration,
			y: "120vh",
			scale: 0.7,
			ease: "power4.in",
			delay,
		})

		tl.to("main", {
			clearProps: "all",
		})
	}

	const onProgress = (progress: number) => {
		setHeading(progress * 3.6)
	}

	usePreloader({
		callback: fadeOut,
		duration: duration + delay,
	})
	loader.useEventListener("progressUpdated", onProgress)

	return (
		<Wrapper ref={wrapper}>
			<StaticCompass heading={heading} />
			<Arrows />
			<StaticCompass heading={heading} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: fixed;
	pointer-events: none;
	z-index: 999;
	inset: 0;
	background: ${colors.black};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	${fresponsive(css`
		padding: 40px 15px;
	`)}
`

const StaticCompass = styled(Compass)`
	position: static;
	transform: unset;
	opacity: 0.5;
	transform-origin: unset;
	will-change: transform;
`

const arrowsAnimation = keyframes`
  0% {
    opacity: 1;
  }
  16.5% {
    opacity: 1;
  }
  16.501% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.3
  }
`

const Arrows = styled(ArrowsSVG)`
	path {
		animation: ${arrowsAnimation} 1200ms infinite;
	}

	path.path2 {
		animation-delay: 200ms;
	}

	path.path3 {
		animation-delay: 400ms;
	}

	${fresponsive(css`
		width: 24px;
	`)}
`
