module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aerodome","short_name":"Aerodome","start_url":"/","background_color":"#ffffff","theme_color":"#1F1F1F","display":"minimal-ui","icon":"./src/images/global/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0532124ee1614c7e3518b3da7d5214d7"},
    },{
      plugin: require('../node_modules/.pnpm/@tmttn+gatsby-plugin-hubspot-tracking@5.2.0_gatsby@5.13.7_react-dom@18.3.1_react@18.3.1/node_modules/@tmttn/gatsby-plugin-hubspot-tracking/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"39655947","respectDNT":true,"productionOnly":false,"region":"us"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_gatsby@5.13.7_rea_zpf5sokthloqfuumyumkmjgehe/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.5.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
