import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"

const NavContext = createContext<{
	menuDark: boolean
	setMenuDark: React.Dispatch<React.SetStateAction<boolean>>
	paused: boolean
	setPaused: React.Dispatch<React.SetStateAction<boolean>>
	removeNav: boolean
	setRemoveNav: React.Dispatch<React.SetStateAction<boolean>>
}>({
	menuDark: false,
	setMenuDark: () => false,
	paused: false,
	setPaused: () => false,
	removeNav: false,
	setRemoveNav: () => false,
})

export const useNavConfig = (options?: {
	menuDark?: boolean
	paused?: boolean
	removeNav?: boolean
}) => {
	const { menuDark, setMenuDark, paused, setPaused, removeNav, setRemoveNav } =
		useContext(NavContext)

	useEffect(() => {
		if (typeof options?.menuDark === "boolean") {
			setMenuDark(options.menuDark)
		}
		if (typeof options?.paused === "boolean") {
			setPaused(options.paused)
		}
		if (typeof options?.removeNav === "boolean") {
			setRemoveNav(options.removeNav)
		}
	}, [
		options?.menuDark,
		options?.paused,
		options?.removeNav,
		setMenuDark,
		setPaused,
		setRemoveNav,
	])

	return { menuDark, paused, removeNav }
}

type Props = {
	children: ReactNode
}

export default function NavProvider({ children }: Props) {
	const [menuDark, setMenuDark] = useState<boolean>(false)
	const [paused, setPaused] = useState<boolean>(false)
	const [removeNav, setRemoveNav] = useState<boolean>(false)

	const value = useMemo(
		() => ({
			menuDark,
			setMenuDark,
			paused,
			setPaused,
			removeNav,
			setRemoveNav,
		}),
		[menuDark, paused, removeNav],
	)

	return <NavContext.Provider value={value}>{children}</NavContext.Provider>
}
